import { inject, Injectable } from '@angular/core';
import { IStepOption, TourService } from 'ngx-ui-tour-md-menu';
import { HeaderSearchService } from '../header-search/header-search.service';

@Injectable({
  providedIn: 'root',
})
export class CommonTourService {
  readonly tourSteps: IStepOption[] = [
    {
      anchorId: 'multiple-dashboard',
      content: 'translation.HelpTour.MultipleDashboard',
      route: 'cdm-metrics/my-dashboard',
      showProgress: true,
      placement: { xPosition: 'before', yPosition: 'below', horizontal: false },
    },
    {
      anchorId: 'contrastdashboard',
      content: 'translation.HelpTour.contrastdashboard',
      showProgress: true,
      placement: { xPosition: 'after', yPosition: 'below' },
    },
    {
      anchorId: 'hospital',
      content: 'translation.HelpTour.hospital',
      placement: { xPosition: 'after', yPosition: 'below' },
    },
    {
      anchorId: 'reportview',
      content: 'translation.HelpTour.reportview',
      showProgress: true,
      placement: { xPosition: 'after', yPosition: 'below' },
    },
    {
      anchorId: 'datepicker',
      content: 'translation.HelpTour.datepicker',
      showProgress: true,
      placement: { xPosition: 'before', yPosition: 'below' },
    },
    {
      anchorId: 'consolidated',
      content: 'translation.HelpTour.Consolidated',
      showProgress: true,
      placement: { xPosition: 'after', yPosition: 'below' },
    },
    {
      anchorId: 'monthlyinjection',
      content: 'translation.HelpTour.monthlyinjection',
      showProgress: true,
    },
    {
      anchorId: 'contrastuse',
      content: 'translation.HelpTour.contrastuse',
      showProgress: true,
    },
    {
      anchorId: 'syringeuse',
      content: 'translation.HelpTour.syringeuse',
      showProgress: true,
    },
    {
      anchorId: 'indicatorperformance',
      content: 'translation.HelpTour.indicatorperformance',
    },
    {
      anchorId: 'timeseriesGraph',
      content: 'translation.HelpTour.timeseries',
    },
    {
      anchorId: 'summary',
      content: 'translation.HelpTour.summary',
      placement: { xPosition: 'after', yPosition: 'above', horizontal: false },
    },
    {
      anchorId: 'repeatinjections',
      content: 'translation.HelpTour.repeatinjections',
      placement: { xPosition: 'after', yPosition: 'above', horizontal: true },
    },
    {
      anchorId: 'ivissues',
      content: 'translation.HelpTour.ivissues',
      placement: { xPosition: 'after', yPosition: 'above', horizontal: true },
    },
    {
      anchorId: 'losttime',
      content: 'translation.HelpTour.losttime',
      placement: { xPosition: 'after', yPosition: 'above', horizontal: true },
    },
    {
      anchorId: 'techabort',
      content: 'translation.HelpTour.techabort',
      placement: { xPosition: 'after', yPosition: 'above', horizontal: true },
    },
    {
      anchorId: 'flowratelimit',
      content: 'translation.HelpTour.flowratelimit',
      placement: { xPosition: 'after', yPosition: 'above', horizontal: true },
    },
    {
      anchorId: 'salinetestinject',
      content: 'translation.HelpTour.salinetestinject',
      placement: { xPosition: 'after', yPosition: 'above', horizontal: true },
    },
    {
      anchorId: 'unusedcontrast',
      content: 'translation.HelpTour.unusedcontrast',
      placement: { xPosition: 'after', yPosition: 'above', horizontal: true },
    },
    {
      anchorId: 'datacompleteness',
      content: 'translation.HelpTour.datacompleteness',
      placement: { xPosition: 'after', yPosition: 'above', horizontal: true },
    },
    {
      anchorId: 'kpi-indicator',
      content: 'translation.helptour.text16',
    },
    {
      anchorId: 'benchmark-indicator',
      content: 'translation.helptour.text17',
    },
    {
      anchorId: 'observation-indicator',
      content: 'translation.helptour.text18',
    },
    {
      anchorId: 'graph',
      content: 'translation.helptour.text20',
    },
    {
      anchorId: 'reports',
      content: 'translation.helptour.text33',
      route: 'report/standardreport',
      placement: { xPosition: 'after', yPosition: 'below', horizontal: false },
    },
  ];

  public readonly tourService = inject(TourService);

  constructor(private readonly headerservice: HeaderSearchService) {
    this.tourService.initialize(this.tourSteps, {
      enableBackdrop: true,
      backdropConfig: {
        offset: 10,
      },
      showProgress: true,
      disablePageScrolling: true,
    });
  }

  startTour() {
    this.headerservice.updateHospitalLocation(
      '121115,125372,4595511',
      '121115 - SALEM COMMUNITY HOSPITAL, 125372 - Salem Community Hospital, 4595511 - St Vincent Public Hospital',
      '/cdm-metrics/my-dashboard'
    );
    this.tourService.start();
  }
}
